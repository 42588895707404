import React from "react";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

function BrushStroke(props: any) {
  return (
    <svg {...props} version="1.0" xmlns="http://www.w3.org/2000/svg"
     width="60.000000pt" height="80.000000pt" viewBox="0 0 1200.000000 1280.000000"
     preserveAspectRatio="xMidYMid meet" fill="currentColor">
    <metadata>
    Created by potrace 1.15, written by Peter Selinger 2001-2017
    </metadata>
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M11661 12784 c-6 -10 -32 -62 -58 -114 -50 -99 -74 -119 -100 -84 -7
        10 -13 32 -13 50 0 42 -43 94 -76 94 -33 0 -67 -38 -116 -128 -74 -134 -115
        -162 -156 -104 -16 22 -18 38 -14 85 4 47 1 62 -14 78 -17 18 -26 20 -87 14
        -63 -7 -70 -5 -94 16 l-25 24 6 -30 c13 -66 15 -125 7 -169 -11 -61 -18 -67
        -43 -33 -25 34 -59 52 -116 62 -42 7 -79 40 -96 89 -10 30 -21 9 -45 -84 -12
        -47 -27 -100 -32 -117 l-11 -33 -92 0 c-219 0 -490 -48 -1426 -255 -184 -40
        -375 -81 -425 -89 -49 -9 -100 -19 -112 -21 -18 -4 -23 -1 -23 15 0 11 -2 20
        -4 20 -3 0 -38 -7 -78 -15 -99 -21 -292 -48 -608 -85 -771 -89 -1085 -159
        -1376 -305 l-124 -62 -26 58 c-35 76 -103 140 -175 164 -29 10 -60 22 -67 28
        -20 16 -23 3 -13 -44 13 -56 14 -160 2 -192 -8 -21 -17 -25 -71 -29 -36 -3
        -67 -11 -76 -20 -8 -8 -19 -45 -25 -82 -14 -104 -42 -142 -139 -191 -19 -10
        -53 -27 -74 -37 -227 -114 -880 -53 -1421 134 -104 36 -289 120 -299 137 -15
        23 -36 9 -61 -39 -57 -112 -119 -154 -245 -165 -99 -9 -131 -19 -165 -55 -36
        -38 -46 -38 -39 0 7 35 6 35 -32 15 -65 -34 -235 -17 -639 64 -238 48 -414 67
        -492 54 -78 -14 -88 -21 -72 -56 10 -22 9 -31 -5 -52 -9 -14 -28 -31 -41 -37
        -41 -18 -61 11 -106 156 -22 72 -53 151 -67 176 l-27 45 -6 -125 c-12 -239
        -71 -457 -150 -559 l-19 -24 0 42 c0 22 -4 41 -9 41 -6 0 -70 -91 -144 -202
        -79 -120 -154 -222 -183 -248 l-49 -45 -3 114 -3 115 -32 -49 c-18 -27 -51
        -63 -75 -80 -42 -29 -48 -30 -168 -33 l-125 -4 -52 40 c-29 21 -92 74 -142
        116 -100 85 -210 146 -279 153 -59 7 -126 -18 -178 -65 -21 -19 -41 -33 -43
        -30 -3 2 -23 85 -46 184 -23 98 -52 226 -66 284 -13 58 -27 120 -30 138 -3 17
        -9 32 -14 32 -27 0 -33 -154 -69 -1785 -66 -2976 -66 -5453 1 -6895 20 -451
        68 -1004 100 -1174 5 -27 9 -104 9 -172 0 -68 2 -124 5 -124 2 0 16 5 30 12
        19 9 28 9 37 0 18 -18 43 -15 63 8 23 26 35 17 35 -26 0 -38 -15 -43 -66 -24
        -35 14 -38 9 -19 -26 8 -16 15 -44 15 -62 0 -42 -29 -184 -40 -195 -4 -5 -13
        15 -19 45 -26 118 -53 190 -85 226 -41 44 -69 40 -60 -10 28 -155 61 -220 143
        -284 32 -25 69 -64 82 -87 22 -39 24 -40 27 -17 2 14 -1 47 -8 75 -11 47 -10
        55 20 133 18 46 43 96 55 110 l23 27 22 -20 c37 -34 42 -54 51 -207 4 -84 14
        -160 21 -175 10 -21 36 -37 118 -71 58 -24 188 -79 290 -122 237 -100 294
        -122 410 -159 215 -68 344 -91 521 -91 l122 0 -5 -65 -6 -64 48 24 c26 13 96
        38 154 54 58 16 127 41 152 55 l45 25 10 -25 c12 -34 39 -199 39 -246 0 -21 5
        -38 10 -38 6 0 10 4 10 10 0 5 18 23 40 39 90 65 315 106 551 99 l147 -4 4
        198 c2 109 8 201 12 205 13 13 41 -13 53 -51 7 -21 20 -96 28 -168 17 -141 25
        -168 55 -168 25 0 28 6 40 91 15 103 28 147 51 170 18 18 22 18 47 5 15 -8 43
        -30 61 -50 43 -44 68 -46 82 -6 6 16 15 30 19 30 9 0 33 -61 48 -120 l9 -35 7
        30 c4 17 12 40 17 53 9 23 9 23 39 5 27 -16 32 -16 40 -3 14 22 12 77 -5 139
        -8 30 -11 59 -8 65 16 27 76 96 84 96 23 0 44 -182 29 -260 -6 -30 -6 -58 -2
        -62 21 -21 289 -105 532 -166 362 -93 628 -135 860 -136 163 -1 224 8 264 37
        16 12 17 17 3 70 -8 31 -31 78 -50 104 -41 58 -63 122 -76 223 -8 66 -7 80 11
        118 19 40 40 53 53 32 10 -16 36 -12 51 8 12 15 13 13 14 -20 0 -49 26 -182
        41 -210 6 -13 31 -43 54 -68 50 -52 71 -107 89 -228 13 -94 26 -98 27 -8 1 34
        4 86 8 116 l7 55 13 -77 c8 -43 17 -78 21 -78 6 0 135 146 167 189 2 3 20 -12
        41 -33 40 -42 115 -80 141 -71 36 11 93 85 156 205 76 143 91 163 120 157 21
        -3 21 0 18 112 -3 110 -2 114 12 84 8 -18 17 -33 20 -33 3 0 19 22 36 50 17
        27 37 50 44 50 7 0 15 -17 19 -40 7 -45 17 -48 64 -20 45 28 71 91 79 189 l6
        80 24 -67 c45 -123 54 -171 42 -230 -10 -48 -9 -55 8 -67 28 -22 62 -18 74 8
        8 17 6 33 -10 71 -12 26 -21 52 -21 57 0 21 59 7 107 -26 29 -19 64 -35 78
        -35 31 0 58 40 81 119 15 51 15 56 -6 123 -25 78 -22 98 15 98 60 0 82 -37
        109 -180 25 -128 22 -162 -19 -222 -19 -28 -32 -55 -30 -58 6 -10 89 6 130 26
        45 21 103 81 145 150 32 53 33 57 16 69 -16 13 -16 15 5 57 61 120 150 216
        449 483 118 106 231 210 251 231 80 85 171 209 208 282 62 124 63 129 30 298
        l-29 149 41 95 c51 118 63 132 55 65 -3 -27 -10 -142 -16 -255 -6 -113 -12
        -234 -15 -270 -2 -36 -7 -110 -11 -164 l-7 -99 44 7 c237 38 699 99 884 117
        362 33 615 17 716 -46 29 -19 39 -34 52 -82 21 -72 22 -261 3 -376 -9 -54 -9
        -76 -2 -74 17 5 47 75 58 136 20 106 31 136 53 136 17 0 20 -8 23 -55 2 -30
        -2 -88 -8 -128 -6 -41 -10 -76 -7 -78 2 -3 17 -6 33 -7 29 -2 29 -1 28 53 0
        30 -5 102 -12 160 -6 58 -14 213 -18 344 -6 216 -5 239 9 233 9 -3 34 -11 57
        -17 l41 -11 -2 -105 c-1 -58 1 -107 4 -110 2 -3 14 60 27 138 43 283 88 407
        134 369 6 -5 36 -19 66 -32 49 -19 80 -23 280 -29 124 -4 233 -11 243 -15 33
        -14 14 -139 -38 -254 -14 -29 -25 -55 -25 -57 0 -2 11 -4 24 -4 13 0 30 -7 37
        -17 12 -14 15 -9 20 46 4 45 12 68 29 86 16 17 26 44 31 82 13 95 57 200 104
        250 l43 44 102 -3 101 -3 33 36 c67 72 62 5 101 1364 20 682 42 1456 50 1720
        8 264 31 1020 50 1680 19 660 42 1430 50 1710 21 717 53 1867 65 2340 16 661
        22 845 27 845 13 0 63 -32 63 -40 0 -6 5 -10 10 -10 13 0 4 40 -20 97 -13 30
        -26 44 -45 49 -49 12 -55 -5 -62 -194 -5 -130 -10 -175 -20 -184 -21 -17 -30
        3 -38 85 -6 56 -12 75 -33 95 -23 24 -24 30 -19 102 11 138 68 239 145 256 43
        9 49 12 23 13 -14 1 -44 20 -72 46 -67 62 -78 55 -104 -70 -13 -61 -26 4 -34
        173 -6 117 -13 176 -23 197 -15 27 -16 28 -27 9z"/>
    </g>
  </svg>)
}

export type CardState = {
  id: string,
  word?: string,
  picture?: string,
  audio?: string[],
  glyph?: string,

  selected?: boolean,
  hidden?: boolean,
  shake?: boolean,
  src: {
    word: string,
    pictures: string[],
    audio?: string[],
    props?: any
  }
  // sound?: string,
  // position: {x: number, y: number},
  // order: number,
}

export default function Card({card, ...props}: any) {
  props.className = `card ${card.color? 'color': card.picture ? 'picture' : card.glyph? 'glyph': 'word'} ${props.className || ''}`
  return (
      <div {...props} >
        {card.color?
          <BrushStroke style={{color: card.color}} />
        : card.picture?
          <img src={`/pictures/${card.picture}`} alt={card.id} onDragStart={(e)=> e.preventDefault()}/>
        : card.glyph?
          <span className="glyph">{card.glyph}</span>
        : card.word? (
          [
            <VolumeUpIcon sx={{mb: 1}} key={'icon'}/>,
            <span key={'word'}>{card.word.replace('_', ' ')}</span>
          ]
        )
        : null}
      </div>
  );
}
